document.addEventListener('DOMContentLoaded', function(e){

    $("#open-modal-0").animatedModal();
    $("#open-modal-1").animatedModal();
    $("#open-modal-2").animatedModal();
    $("#open-modal-3").animatedModal();
    $("#open-modal-4").animatedModal();
    $("#open-modal-5").animatedModal();
    $("#open-modal-6").animatedModal();
    $("#open-modal-7").animatedModal();


    $('#reload').on('click', function(e){
        window.location.reload();
        console.log('reloading');
    })

})